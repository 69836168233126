<template>
    <div class="register-additional-info">
        <h2 class="register-additional-info__title">
            WHY USE OUR COMPANY?
        </h2>
        <ul>
            <li class="register-additional-info__item">
                <span class="register-additional-info__check-img">
                    <CheckCircleNew />
                </span>
                <span class="register-additional-info__description">
                    Our established <b>family of writers</b> - our great pride!
                </span>
            </li>

            <li class="register-additional-info__item">
                <span class="register-additional-info__check-img">
                    <CheckCircleNew />
                </span>
                <span class="register-additional-info__description">
                    Great Customer Care & Support
                </span>
            </li>

            <li class="register-additional-info__item">
                <span class="register-additional-info__check-img">
                    <CheckCircleNew />
                </span>
                <span class="register-additional-info__description">
                    Writers that <b>follow instructions & deliver on time</b>
                </span>
            </li>

            <li class="register-additional-info__item">
                <span class="register-additional-info__check-img">
                    <CheckCircleNew />
                </span>
                <span class="register-additional-info__description">
                    Secure website & confidential service
                </span>
            </li>

            <li class="register-additional-info__item">
                <span class="register-additional-info__check-img">
                    <CheckCircleNew />
                </span>
                <span class="register-additional-info__description">
                    Moneyback guarantee
                </span>
            </li>
        </ul>
    </div>
</template>

<script>

import CheckCircleNew from '@/components/icons/CheckCircleNew.vue';

export default {
    name: 'RegisterAdditionalInfo',
    components: {
        CheckCircleNew
    }
}
</script>

<style scoped lang="scss">

.register-additional-info {
    width: 40%;
    padding: 60px 40px;
    background-color: rgba($font-color-main,.9);
    border-radius: 12px 0 0 12px;
    color: $white;
    @media (max-width: 1000px) {
        border-radius: 0;
    }
    @media (max-width: 650px) {
        width: 100%;
    }
    &__title{
        padding-bottom: 30px;
        font-size: 20px;
        margin-top: 60px;
        @media (max-width: 650px) {
            margin-top: 0px;
        }
    }
    &__item{
        display: flex;
        margin-bottom: 25px;
        font-size: 16px;
    }
    &__check-img {
        //background-image: url("~@/assets/img/svg/check-green.svg");
        //background-repeat: no-repeat;
        //background-size: 20px;
        //width: 20px;
        //height: 20px;
        //padding: 0 10px;
    }
    &__description{
        margin-left: 15px;
        line-height: 130%;
    }
}

</style>
