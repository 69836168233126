<template>
    <p class="agree_term">
        By creating an account, you agree to the
        <router-link
            :to="{name: 'terms'}"
            class="agree_term-link"
        >
            Terms and Conditions
        </router-link>and
        <router-link
            :to="{name: 'privacy'}"
            class="agree_term-link"
        >
            Privacy Policy.
        </router-link>
    </p>
</template>

<script>
export default {
    name: 'TermsAndPrivacy'
};
</script>

<style scoped lang="scss">
.agree_term{
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
    color: $font-color-main;
    &-link {
        color: $main-color;
        font-weight: 500;
        &:hover{
            color: $white;
            text-decoration: underline;
        }
    }
}
</style>
