<template>
    <entering-form-layout>
        <div class="authentication__form-wrapper">
            <div
                class="authentication__form"
                :style="{width: $route.name === 'register' ? '1000px' : ''}"
            >
                <!-- signing login section -->
                <div class="signin-section form-block">
                    <div class="form-block__body-wrapper">
                        <RegisterAdditionalInfo />
                        <div
                            class="form-block__body"
                            :class="{'form-block__body--register': $route.name === 'register'}"
                        >
                            <div class="form-block__header">
                                <h2 class="form-block__title">
                                    Register
                                </h2>
                            </div>
                            <div class="form-block__body--switch">
                                <form-switch
                                    :id="'stay_anonymous'"
                                    v-model="stay_anonymous"
                                />
                                <span class="switch_text">
                                    I would like to remain anonymous
                                </span>
                            </div>
                            <div
                                v-if="!stay_anonymous"
                                style="display: flex; justify-content: space-between"
                            >
                                <form-input
                                    v-model="$v.firstname.$model"
                                    style="width: 48%"
                                    legend="Firstname"
                                    type="text"
                                    name="firstname"
                                    :field.sync="firstname"
                                    :errors="validationMessage($v.firstname)"
                                    :is-valid="$v.firstname.$dirty && !$v.firstname.$anyError"
                                    @input.native="$v.firstname.$touch();"
                                    @blur="$v.firstname.$touch();"
                                />
                                <form-input
                                    v-model="$v.lastname.$model"
                                    style="width: 48%"
                                    legend="Lastname"
                                    type="text"
                                    name="lastname"
                                    :field.sync="lastname"
                                    :errors="validationMessage($v.lastname)"
                                    :is-valid="$v.lastname.$dirty && !$v.lastname.$anyError"
                                    @input.native="$v.lastname.$touch();"
                                    @blur="$v.lastname.$touch();"
                                />
                            </div>
                            <form-input
                                v-model.trim="emailValue"
                                legend="Email address"
                                prepend-icon="EmailOutlineIcon"
                                type="text"
                                name="email"
                                :field.sync="emailValue"
                                :errors="validationMessage($v.email)"
                                :is-valid="$v.email.$dirty && !$v.email.$anyError"
                                :loading="loadEmail"
                                autocomplete="username"
                                @input.native="$v.email.$touch()"
                                @onChange="loadEmail = true"
                            />
                            <!-- <email /> -->
                            <div
                                v-show="$v.email.$params.emailExists"
                                class="forgot-alert"
                            >
                                <information />
                                <div class="forgot-alert__content">
                                    <span class="forgot-alert__content__text">Existing users can</span>
                                    <router-link
                                        :to="{ name: 'forgot-password', params: {} }"
                                        class="forgot-alert__content__link"
                                    >
                                        reset password here
                                    </router-link>
                                </div>
                            </div>
                            <div class="password_field">
                                <form-input
                                    v-model="$v.password.$model"
                                    legend="Password"
                                    prepend-icon="LockIcon"
                                    :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                                    :type="showPassword ? 'text' : 'password'"
                                    name="password"
                                    :maxlength="20"
                                    autocomplete="new-password"
                                    :field.sync="password"
                                    :errors="validationMessage($v.password)"
                                    :is-valid="$v.password.$dirty && !$v.password.$anyError"
                                    @clicked="showPassword = !showPassword"
                                    @input.native="$v.password.$touch()"
                                    @blur="$v.password.$touch(); passwordInFocus = false"
                                    @focus="passwordInFocus = true"
                                />
                                <div
                                    v-if="passwordInFocus && $v.password.$anyError"
                                    class="password-validation"
                                >
                                    <password-validator-helper :password="password" />
                                </div>
                            </div>
                            <!-- <phone-number
                                @phoneValidated="validatePhone"
                            /> -->

                            <form-checkbox
                                v-model="chk_email_marketing"
                                class="agree_email"
                                color="orange darken-2"
                                :label="'Agree to receive e-mails (newsletter, discount codes, promotional materials.)'"
                            />

                            <button
                                class="btn-base btn-main button-width-100"
                                :class="{'btn-disabled': !isFormValid}"
                                @click="validate"
                            >
                                <template v-if="loading">
                                    <button-loader />
                                </template>
                                <span v-else>Register me</span>
                            </button>
                            <social-login
                                mode="register"
                                class="my-3"
                            />
                            <div class="register-terms-and-privacy--mobile">
                                <TermsAndPrivacy />
                            </div>
                        </div>
                    </div>
                    <div class="form-block__footer" />
                    <div class="register-terms-and-privacy--desctop">
                        <TermsAndPrivacy />
                    </div>
                </div>
                <!-- signing login section -->
            </div>
        </div>
    </entering-form-layout>
</template>

<script>

// event bus
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import Information from 'mdi-vue/Information.vue'
import {
    createNamespacedHelpers
} from 'vuex'
import { debounce } from 'lodash'
import { eventBus } from '@/helpers/event-bus/'

// components
import EnteringFormLayout from '@/layouts/EnteringFormLayout';
import ButtonLoader from '@/components/common/ButtonLoader.vue'
import SocialLogin from '@/components/common/SocialLogin';
import TermsAndPrivacy from '@/components/common/TermsAndPrivacy';
import RegisterAdditionalInfo from '@/views/auth/RegisterAdditionalInfo';

// validation
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/register/Rules'
import { formMessages } from '@/validation/register/Messages'
import PasswordValidatorHelper from '@/components/PasswordValidatorHelper';

// types
import {
    ACTION_CREATE_CLIENT,
    ACTION_LOGIN_CLIENT
} from '@/store/modules/client/action-types'

// services
import { deleteUTMMedium, deleteUTMSource, deleteUTMCampaign } from '@/services/utm'

const { mapActions: mapClientActions } = createNamespacedHelpers('client')

export default {
    name: 'Register',
    components: {
        EnteringFormLayout,
        // PhoneNumber,
        ButtonLoader,
        Information,
        SocialLogin,
        PasswordValidatorHelper,
        RegisterAdditionalInfo,
        TermsAndPrivacy
    },
    metaInfo: {
        title: 'Registration'
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data: () => ({
        emailValue: '',
        showPassword: false,
        loading: false,
        phoneValidationStatus: false,
        loadEmail: false,
        passwordInFocus: false,
        stay_anonymous: false,
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        chk_email_marketing: true
    }),
    computed: {
        isFormValid() {
            // return !this.$v.$invalid && this.isPhoneVerified
            return !this.$v.$invalid
        }
    },
    watch: {
        emailValue: debounce(function (newEmail) {
            this.email = newEmail.trim()
            this.loadEmail = false
        }, 300)
    },
    methods: {
        ...mapClientActions([
            ACTION_CREATE_CLIENT,
            ACTION_LOGIN_CLIENT
        ]),
        validationMessage: validationMessage(formMessages),
        // validatePhone(value) {
        //     this.phoneValidationStatus = value
        // },
        async submit() {
            try {
                this.loading = true
                // Create client
                await this[ACTION_CREATE_CLIENT]({
                    firstname: this.stay_anonymous ? null : this.firstname,
                    lastname: this.stay_anonymous ? null : this.lastname,
                    email: this.email,
                    password: this.password,
                    stay_anonymous: this.stay_anonymous,
                    chk_email_marketing: this.chk_email_marketing
                })
                eventBus.$emit('showSnackBar', 'Your account has been created', 'success')
                this.$router.push({ name: 'auth-intermediate' })
                deleteUTMMedium()
                deleteUTMSource()
                deleteUTMCampaign()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
    .my-3 {
        margin-top: 20px;
    }
    .btn-disabled {
        pointer-events: none;
    }

    .form-block__body {
        .phone_wrapper {
            .phone__row {
                flex-wrap: nowrap;
                .phone-verification__btn {
                    width: inherit;
                    margin-left: 5px;
                }
            }
        }
        .form-input__fieldset input {
            font-size: 17px !important;
        }
    }
    .agree_email{
        margin-top: -10px;
        margin-bottom: 20px;
    }

    .register-terms-and-privacy{
        &--mobile{
            display: none;
            @media (max-width: 650px) {
                margin-top: 15px;
                display: flex;
            }
        }
        &--desctop{
            @media (max-width: 650px) {
                display: none;
            }
        }
    }
</style>
